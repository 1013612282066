// eslint-disable-next-line import/named
import Address, { ErrorAddress } from 'requests/objects/address'
import ApiHandler from 'requests/apiHandler'
import { ParamElement } from 'requests/objects/param'
import Contact from 'requests/objects/contact'
import DeliveryTime from 'requests/objects/deliveryTime'
import Department from 'requests/objects/department'

/**
 * AddressesHandler
 * @augments {ApiHandler<Address, ErrorAddress>}
 */
export default class AddressesHandler extends ApiHandler {
    constructor() {
        super({
            type: Address, errorType: ErrorAddress, key: 'addresses', automicErrorMessageDisplay: false,
        })
    }

    /**
     * Get all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<Address[]>} Request
     */
    getAllToValidated(params = {}) {
        const request = this.initFetchRequest({ params, url: ['validation'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => res.data.addresses?.map(x => new Address(x)))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {Address} address Request
     * @param {number=} addressId addressId
     * @returns {import('requests/apiHandler').RequestApi<Address>} Request
     */
    upsert(address, addressId = undefined) {
        const request = this.initFetchRequest({
            url: addressId ? [addressId] : [],
            method: addressId ? 'PUT' : 'POST',
            data: address,
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Address(res.data.addresses) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * reject
     * @param {number=} addressId addressId
     * @returns {import('requests/apiHandler').RequestApi<Address>} Request
     */
    reject(addressId) {
        const request = this.initFetchRequest({
            url: ['reject', addressId],
            method: 'PATCH',
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Address(res.data.addresses) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import
     * @param {globalThis.File} file file
     * @param {number} clientId clientId
     * @returns {import('requests/apiHandler').RequestApi<Address[]>} Returns
     */
    import(file = undefined, clientId = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: ['import', clientId],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => res.data?.addresses?.map(a => new Address(a)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {string} instructions Request
     * @param {number} addressId addressId
     * @returns {import('requests/apiHandler').RequestApi<Address>} Request
     */
    updateAddressInstructions(instructions, addressId) {
        const request = this.initFetchRequest({
            url: [addressId, 'instructions'],
            method: 'PATCH',
            data: { instructions },
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Address(res.data.addresses) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * getSuggestedAddresses
     * @param {string} string string
     * @returns {import('requests/apiHandler').RequestApi<ParamElement[]>} Element
     */
    searchGoogleAddress(string) {
        const request = this.initFetchRequest({ url: ['google-addresses', 'search'], params: { string } })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data['google-addresses']?.map(x => new ParamElement(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Add or remove favorite
     * @param {number} id address id
     * @returns {import('requests/apiHandler').RequestApi<Address>} Request
     */
    setAddressToDefault(id) {
        const request = this.initFetchRequest({ url: [id, 'assign-default'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert contact to address
     * @param {number} addressId address id
     * @returns {import('requests/apiHandler').RequestApi<Contact>} Request
     */
    getAddressContact(addressId) {
        const request = this.initFetchRequest({ url: [addressId, 'contact'] })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Contact(res.data.contact) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert contact to address
     * @param {number} addressId address id
     * @returns {import('requests/apiHandler').RequestApi<Department>} Request
     */
    getDepartement(addressId) {
        const request = this.initFetchRequest({ url: [addressId, 'department'] })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Department(res.data.department) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get delivery time
     * @param {number} pickupAddressId pickup address id
     * @param {number} deliveryAddressId delivery address id
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime>} Request
     */
    getDeliveryTime(pickupAddressId, deliveryAddressId) {
        const request = this.initFetchRequest({
            url: ['delivery-time'],
            params: { pickupAddressId, deliveryAddressId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new DeliveryTime(res.data.deliveryTime) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Download template
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    downloadTemplate() {
        const request = this.initFetchRequest({
            method: 'GET',
            url: ['addresses-template'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Download example
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    downloadExample() {
        const request = this.initFetchRequest({
            method: 'GET',
            url: ['addresses-example'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    // /**
    //  * Get open days
    //  * @param {number} addressId address id
    //  * @returns {import('requests/apiHandler').RequestApi<OpenDays>} Request
    //  */
    // getOpenDays(addressId) {
    //     const request = this.initFetchRequest({
    //         url: [addressId, 'open-days'],
    //     })

    //     return this.getRequestApi(
    //         () => request.fetchRequest
    //             // eslint-disable-next-line new-cap
    //             .then(res => new OpenDays(res.data.openDays) ?? {})
    //             .catch(err => {
    //                 throw this.handleError(err)
    //             }),
    //         request.cancelToken,
    //     )
    // }

    /**
     * Upsert contact to address
     * @param {number} addressId address id
     * @param {object} contactData contact data object
     * @returns {import('requests/apiHandler').RequestApi<Contact>} Request
     */
    upsertContact(addressId, contactData) {
        const request = this.initFetchRequest({
            url: [addressId, 'contact'],
            method: contactData.contactId ? 'PUT' : 'POST',
            data: contactData,
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Contact(res.data.contact) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * @param {string} placeId placeId
     * @returns {import('requests/apiHandler').RequestApi<Address>} Result
     */
    findGoogleAddressDetail(placeId) {
        const request = this.initFetchRequest({ url: ['google-addresses', placeId] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Address(res.data.address) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
