import useTranslate from 'helpers/hooks/useTranslate'
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { setModal } from 'redux/slices/common'
import store from 'redux/store'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import AddressesHandler from 'requests/handlers/addressesHandler'
import Address, { ErrorAddress } from 'requests/objects/address'
import CompanyRule from 'requests/objects/companyRule'
import { ParamElement } from 'requests/objects/param'
import ECompany from 'types/companies/enums/company'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import Status from 'types/status'

/**
 * useAddressModal
 * @param {object} props Props
 * @param {Partial<Address>=} props.addressData addressData, clientId is required at creation for admin
 * @param {boolean} props.isVisible isVisible
 * @param {boolean} props.addressCanBeOptional addressCanBeOptional
 * @param {boolean} props.isValidation isValidation
 * @param {object} props.lang Lang
 * @param {AddressesHandler} props.handler handler
 * @param {(address: Address) => void} props.onChange onChange
 * @param {CompanyRule=} props.companyRules companyRules
 * @param {ParamElement[]=} [props.addresses] addresses
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.editParam] editParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.addParam] addParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.removeParam] removeParam
 * @returns {{
 *  tKey: (key: import('types/translations').TranslationsType, args?: { [key: string]: string; }) => string,
 *  tObj: (obj: import('types/translations').TranslationsObjectType | string | [string], args?: { [key: string]: string; }) => string,
 *  dialogTitle: string,
 *  errorMessage: string,
 *  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
 *  onSubmit: () => Promise<void>,
 *  address: Address,
 *  setAddress: React.Dispatch<React.SetStateAction<Address>>,
 *  errorField: ErrorAddress,
 *  status: string,
 *  addressDataOptionSelected: ParamElement,
 *  setAddressDataOptionSelected: React.Dispatch<React.SetStateAction<ParamElement>>,
 *  onSuggestedAddressesChange: (option: any) => Promise<void>,
 *  hasTimeSlot: boolean,
 *  changesAreControlled: boolean,
 *  addressCanBeInvisible: boolean,
 *  companyName: string,
 *  refuseAddress: () => void
 *  canSave: boolean
 * }} Returns
 */
export default function useAddressModal({
    lang,
    addressData,
    handler,
    isVisible,
    isValidation = false,
    addresses = [],
    addParam = undefined,
    editParam = undefined,
    removeParam = undefined,
    onChange,
    companyRules,
    addressCanBeOptional,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    const [errorMessage, setErrorMessage] = useState('')
    const [errorField, setErrorField] = useState(new ErrorAddress())
    const [status, setStatus] = useState(Status.IDLE)
    const [address, setAddress] = useState(new Address(addressData))
    const [addressDataOptionSelected, setAddressDataOptionSelected] = useState(new ParamElement())

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Partial<Address>>>} */
    const addressHandlerFindData = useRef()
    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const addressHandlerGetById = useRef()
    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const addressHandlerUpsert = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const addressHandlerReject = useRef()

    /** Title to display in header */
    const dialogTitle = useMemo(() => {
        if (!address?.addressId)
            return tKey('modalAddressTitle')
        if (isValidation)
            return tKey('modalValidAddressTitle')
        return tKey('modalEditAddressTitle')
    }, [address.addressId, isValidation, tKey])

    const {
        changesAreControlled,
        companyName,
        hasTimeSlot,
        addressCanBeInvisible,
    } = useMemo(() => ({
        changesAreControlled: companyRules.companyRuleWorkflowStepIds.includes(EWorkflowStep.AddressValidation),
        companyName: companyRules.companyName,
        hasTimeSlot: companyRules.areAddressTimeSlotsMandatory,
        addressCanBeInvisible: addressCanBeOptional,
    }), [addressCanBeOptional, companyRules])

    /**
     * Get address data by id
     */
    const getAddressDataById = useCallback(async addressId => {
        try {
            setStatus(Status.PENDING)
            addressHandlerGetById.current = handler.getById(addressId)
            const addr = await addressHandlerGetById.current.fetch()
            setStatus(Status.RESOLVED)
            setAddress(prevState => ({ ...prevState, ...addr }))
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError: break
                case NotImplementedError: break
                case InvalidEntityError:
                    // eslint-disable-next-line no-case-declarations
                    const err = /** @type {InvalidEntityError<ErrorAddress>} */(error)
                    setErrorField(err.errorField)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, setAddress, setStatus, addressHandlerGetById])

    const refuseAddress = useCallback(() => {
        if (!address?.addressId)
            return
        setStatus(Status.PENDING)
        store.dispatch(setModal({
            show: true,
            title: tKey('rejectAddressTitle'),
            subTitle: tKey('rejectAddressSubTitle'),
            callback: async () => {
                try {
                    addressHandlerReject.current = handler.reject(address.addressId)
                    const addr = await addressHandlerReject.current.fetch()
                    onChange(addr)
                    setStatus(Status.RESOLVED)
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                    setStatus(Status.REJECTED)
                }
            },
        }))
    }, [address.addressId, handler, onChange, tKey])

    // Set address data for edition or creation. Ensure that data in modal are up to date
    useMemo(() => {
        if (addressData.addressId)
            getAddressDataById(addressData.addressId)
        else
            setAddress(prevState => ({ ...prevState, ...addressData }))
    }, [addressData, getAddressDataById])

    /**
     * On suggested addresses change
     */
    const onSuggestedAddressesChange = useCallback(async option => {
        setAddressDataOptionSelected(option)
        if (option.key)
            try {
                setStatus(Status.PENDING)
                addressHandlerFindData.current = handler.findGoogleAddressDetail(option.key)
                const addrResult = await addressHandlerFindData.current.fetch()

                const addressDataState = !address.isManual ? {
                    street: addrResult.street,
                    city: addrResult.city,
                    zipCode: addrResult.zipCode,
                    countryId: addrResult.countryId,
                } : {}

                setAddress(prevState => ({
                    ...prevState,
                    lat: addrResult.lat,
                    lon: addrResult.lon,
                    ...addressDataState,
                }))
                setStatus(Status.RESOLVED)
            } catch (error) {
                setStatus(Status.REJECTED)
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError: break
                    case NotImplementedError: break
                    case InvalidEntityError:
                        // eslint-disable-next-line no-case-declarations
                        const err = /** @type {InvalidEntityError<ErrorAddress>} */(error)
                        setErrorField(err.errorField)
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setErrorMessage(error)
                        break
                }
            }
    }, [handler, address.isManual])

    /**
     * Save the address
     */
    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            addressHandlerUpsert.current = handler.upsert(address, addressData?.addressId)
            const addr = await addressHandlerUpsert.current.fetch()

            if (addParam && editParam && removeParam) {
                const addrFound = addresses.find(x => x.key === addr.addressId)

                /** @type {import('redux/slices/user').PayloadParam} payload param */
                const payload = {
                    key: 'addresses',
                    value: {
                        key: addr.addressId,
                        text: addr.label,
                        data: [
                            address.street,
                            address.zipCode,
                            address.city,
                            address.country?.code,
                        ].filter(x => x).join(', '),
                        addressTypeId: addr.addressTypeId,
                    },
                }
                if (addr.isVisible)
                    if (addrFound)
                        editParam(payload)
                    else
                        addParam(payload)
                else if (addrFound)
                    removeParam(payload)
            }

            onChange(addr)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError: break
                case NotImplementedError: break
                case InvalidEntityError:
                    // eslint-disable-next-line no-case-declarations
                    const err = /** @type {InvalidEntityError<ErrorAddress>} */(error)
                    setErrorField(err.errorField)
                    setErrorMessage(err.message)
                    break
                default:
                    setErrorMessage(error.message || error)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, address, addressData?.addressId, addParam, editParam, removeParam, onChange, addresses])

    // Disable/enable scrollbar of the page
    useEffect(() => {
        if (isVisible)
            document.querySelector('html').classList.add('is-scroll-disable')
        else
            document.querySelector('html').classList.remove('is-scroll-disable')
        return () => {
            document.querySelector('html').classList.remove('is-scroll-disable')
        }
    }, [isVisible])

    // Clean some stuff
    useEffect(() => {
        if (isVisible)
            setErrorField(new ErrorAddress())
    }, [isVisible])

    // On component did unmount
    useEffect(() => () => {
        addressHandlerUpsert.current?.cancel()
        addressHandlerGetById.current?.cancel()
        addressHandlerFindData.current?.cancel()
    }, [])

    return {
        tKey,
        tObj,
        dialogTitle,
        errorMessage,
        setErrorMessage,
        onSubmit,
        address,
        setAddress,
        errorField,
        status,
        addressDataOptionSelected,
        setAddressDataOptionSelected,
        onSuggestedAddressesChange,
        changesAreControlled,
        companyName,
        hasTimeSlot,
        addressCanBeInvisible,
        refuseAddress,
        canSave:
            !!(
                status !== Status.PENDING
                && address.addressTypeId
                && address.label
                && address.street && address.city && address.zipCode && address.countryId
                // && address.lat && address.lon
                && (companyRules.companyId !== ECompany.JetFreeze || address.phoneNumber)
            ),
    }
}
