import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, Label, MessageBar, MessageBarType, PrimaryButton, Text, TextField, Toggle,
} from '@fluentui/react'
import Dropdown from 'components/inputs/dropdown'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import React from 'react'
import AddressesHandler from 'requests/handlers/addressesHandler'
import Address from 'requests/objects/address'
import Param from 'requests/objects/param'
import Status from 'types/status'
import parseJson from 'helpers/methods/parseJson'
import useAddressModal from 'helpers/hooks/components/pages/useAddressModal'
import styles from 'styles/components/pages/address-modal.module.scss'
import AddressModalMap from 'components/pages/addressModalMap'
import { Columns } from 'react-bulma-components'
import classNames from 'classnames'
import PhoneField from 'components/inputs/phoneField'
import CompanyRule from 'requests/objects/companyRule'
import TextFieldWithTooltip from 'components/inputs/textFieldWithTooltip'
import ECompany from 'types/companies/enums/company'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * AddressModal
 * @param {object} props Props
 * @param {Partial<Address>=} props.addressData addressData, clientId is required at creation for admin
 * @param {boolean} props.isVisible isVisible
 * @param {object} props.lang Lang
 * @param {CompanyRule=} props.companyRules isOpeningTimeRequired
 * @param {AddressesHandler} props.handler handler
 * @param {(address: Address) => void} props.onChange onChange
 * @param {boolean} [props.isReadOnly] isReadOnly
 * @param {Param} props.param param
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.editParam] editParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.addParam] addParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} [props.removeParam] removeParam
 * @param {number[]=} props.addressTypeDisableIds addressTypeDisableIds
 * @param {boolean} [props.isValidation] isValidation address step
 * @param {boolean} [props.isAdmin] isAdmin address step
 * @param {boolean} [props.addressCanBeOptional] addressCanBeOptional
 * @returns {JSX.Element} Modal
 */
export default function AddressModal({
    addressData,
    isVisible,
    lang,
    companyRules,
    handler,
    onChange,
    isReadOnly = false,
    param,
    addParam = undefined,
    editParam = undefined,
    removeParam = undefined,
    addressTypeDisableIds = [],
    isValidation = false,
    isAdmin = false,
    addressCanBeOptional = true,
}) {
    const {
        tKey,
        tObj,
        dialogTitle,
        errorMessage,
        setErrorMessage,
        onSubmit,
        address,
        setAddress,
        errorField,
        status,
        addressDataOptionSelected,
        setAddressDataOptionSelected,
        onSuggestedAddressesChange,
        changesAreControlled,
        companyName,
        hasTimeSlot,
        addressCanBeInvisible,
        refuseAddress,
        canSave,
    } = useAddressModal({
        companyRules,
        lang,
        addressData,
        handler,
        isVisible,
        addresses: param.addresses,
        addParam,
        editParam,
        removeParam,
        onChange,
        isValidation,
        addressCanBeOptional,
    })

    return (
        <Dialog
            hidden={!isVisible}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: dialogTitle,
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="100%"
            // minWidth="1000px"
            styles={{
                main: {
                    width: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                },
            }}
        >
            {errorMessage
                && (
                    <>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                        <br />
                    </>
                )}
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    onSubmit()
                }}
                className={styles['address-modal']}
            >
                <div>
                    <FilteredVirtualComboboxAjax
                        label={tKey('suggestedAddresses')}
                        placeholder={tKey('suggestedAddresses')}
                        disabled={status === Status.PENDING}
                        selectedKey={addressDataOptionSelected?.key}
                        onChange={(_ev, option) => onSuggestedAddressesChange(option)}
                        functionName="searchGoogleAddress"
                        handler={handler}
                    />
                    <br />
                    <Text className={styles['address-modal-subtitle']}>
                        {tKey('location')}
                    </Text>
                    <Columns>
                        <Columns.Column>
                            <TextField
                                label={tKey('street')}
                                placeholder={tKey('street')}
                                value={address.street}
                                readOnly={!address.isManual}
                                borderless={!address.isManual}
                                onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, street: newVal }))}
                                required
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.street))}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <TextField
                                label={tKey('city')}
                                placeholder={tKey('city')}
                                value={address.city}
                                readOnly={!address.isManual}
                                borderless={!address.isManual}
                                onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, city: newVal }))}
                                required
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.city))}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <TextField
                                label={tKey('zipcode')}
                                placeholder={tKey('zipcode')}
                                value={address.zipCode}
                                readOnly={!address.isManual}
                                borderless={!address.isManual}
                                onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, zipCode: newVal }))}
                                required
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.zipCode))}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Dropdown
                                label={tKey('country')}
                                placeholder={tKey('country')}
                                readOnly={!address.isManual}
                                borderless={!address.isManual}
                                selectedKey={address.countryId}
                                options={param.countries?.map(x => ({
                                    key: x.key,
                                    text: tObj(parseJson(x.text)),
                                }))}
                                onChange={(ev, option) => setAddress(prevState => ({ ...prevState, countryId: option.key }))}
                                required
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.countryId))}
                            />
                        </Columns.Column>
                    </Columns>
                    <TextFieldWithTooltip
                        label={tKey('complementAddress')}
                        placeholder={tKey('complementAddress')}
                        value={address.complement}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, complement: newVal }))}
                        disabled={status === Status.PENDING}
                        errorMessage={tObj(parseJson(errorField.complement))}
                        tooltipMessage={tKey('complementAddressTooltip')}
                    />
                    {/* <br />
                    <FilteredVirtualCombobox
                        label={tKey(companyRules.companyId === ECompany.JetFreeze ? 'accessibilities' : 'accessibility')}
                        placeholder={tKey('select')}
                        options={param.accessibilities.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                        readOnly={isReadOnly}
                        selectedKey={companyRules.companyId === ECompany.JetFreeze ? address.accessibilityIds : address.accessibilityId}
                        multiSelect={companyRules.companyId === ECompany.JetFreeze}
                        onChange={(ev, option) => setAddress(prevState => (companyRules.companyId === ECompany.JetFreeze
                            ? ({ ...prevState, accessibilityIds: getUpdatedList(prevState.accessibilityIds, option) })
                            : ({ ...prevState, accessibilityId: option.key })))}
                        errorMessage={tObj(parseJson(errorField.accessibilityId))}
                    /> */}
                    <br />
                    <Text className={styles['address-modal-subtitle']}>
                        {tKey('informationFillInAddressInformation')}
                    </Text>
                    <Columns>
                        <Columns.Column>
                            <TextField
                                label={tKey('labelAddress')}
                                placeholder={tKey('labelAddress')}
                                value={address.label}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, label: newVal }))}
                                required
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.label))}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <PhoneField
                                label={tKey('phoneNumber')}
                                placeholder={tKey('phoneNumber')}
                                value={address.phoneNumber}
                                required={companyRules.companyId === ECompany.JetFreeze}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, phoneNumber: newVal }))}
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(parseJson(errorField.phoneNumber))}
                                type="tel"
                            />
                        </Columns.Column>
                    </Columns>
                    {hasTimeSlot && (
                        <Columns>
                            <Columns.Column>
                                <TextField
                                    label={tKey('startTime')}
                                    placeholder={tKey('startTime')}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    value={address.startTime}
                                    onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, startTime: newVal }))}
                                    required
                                    type="time"
                                    errorMessage={tObj(parseJson(errorField.startTime))}
                                    disabled={status === Status.PENDING}
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <TextField
                                    label={tKey('endTime')}
                                    placeholder={tKey('endTime')}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    value={address.endTime}
                                    errorMessage={tObj(parseJson(errorField.endTime))}
                                    onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, endTime: newVal }))}
                                    required
                                    type="time"
                                    min={address?.startTime}
                                    disabled={status === Status.PENDING}
                                />
                            </Columns.Column>
                        </Columns>
                    )}
                    {(isAdmin || isValidation) && (
                        <Columns>
                            <Columns.Column>
                                <TextField
                                    label={tKey('tmsCode')}
                                    placeholder={tKey('tmsCode')}
                                    value={address.addressTmsId}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, addressTmsId: newVal }))}
                                    required
                                    disabled={status === Status.PENDING}
                                    errorMessage={tObj(parseJson(errorField.addressTmsId))}
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <FilteredVirtualCombobox
                                    label={tKey('accessibility')}
                                    placeholder={tKey('select')}
                                    options={param.accessibilities.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    required
                                    selectedKey={address.accessibilityId}
                                    onChange={(ev, option) => setAddress(prevState => ({ ...prevState, accessibilityId: option.key }))}
                                    errorMessage={tObj(parseJson(errorField.accessibilityId))}
                                />
                            </Columns.Column>
                        </Columns>
                    )}
                    {!isValidation && !(!isAdmin && companyRules.companyId === ECompany.Raud) && (
                        <Label
                            required
                        >
                            {tKey('addressType')}
                        </Label>
                    )}
                    {!isValidation && !(!isAdmin && companyRules.companyId === ECompany.Raud) && (
                        <div className={styles['address-modal-addresstype-container']}>
                            {param.addressTypes.map(paramAddressType => (
                                <Text
                                    key={paramAddressType.key}
                                    className={classNames(
                                        styles['address-modal-addresstype'],
                                        { [styles['is-active']]: address.addressTypeId === +paramAddressType.key },
                                        { [styles['is-disable']]: addressTypeDisableIds.includes(/** @type {any} */(paramAddressType.key)) },
                                    )}
                                    onClick={() => {
                                        setAddress(prevState => ({
                                            ...prevState, addressTypeId: +paramAddressType.key,
                                        }))
                                    }}
                                >
                                    {tObj(parseJson(paramAddressType.text))}
                                </Text>
                            ))}
                            <TextField
                                value={address.addressTypeId ? 'ok' : ''}
                                onChange={() => null}
                                styles={{
                                    wrapper: { opacity: 0 },
                                    root: { position: 'absolute', bottom: 0, pointerEvents: 'none' },
                                }}
                                tabIndex={-1}
                                required
                            />
                        </div>
                    )}
                    {!isValidation && addressCanBeInvisible && (
                        <Columns>
                            <Columns.Column
                                style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }}
                            >
                                <Toggle
                                    onText={tKey('yes')}
                                    offText={tKey('no')}
                                    label={tKey(isAdmin ? 'isAddressVisibleAdmin' : 'isAddressVisible')}
                                    styles={{
                                        root: { display: 'flex', justifyContent: 'space-between' },
                                        container: { alignItems: 'center' },
                                    }}
                                    checked={address.isVisible}
                                    onChange={(ev, newVal) => setAddress(prevState => ({ ...prevState, isVisible: newVal }))}
                                    disabled={isReadOnly}
                                />
                            </Columns.Column>
                        </Columns>
                    )}
                    {!isAdmin && !isValidation && changesAreControlled && (
                        <Text
                            className={styles['address-modal-info-warning']}
                            variant="small"
                        >
                            {tKey('addressWillBeControlledByCompany', { companyName })}
                        </Text>
                    )}

                    {!isAdmin && !isValidation && (
                        <Text
                            className={styles['address-modal-info']}
                            variant="smallPlus"
                        >
                            {tKey('toEnsureThatOurDriverArrivesEasily')}
                        </Text>
                    )}
                    <DialogFooter
                        className={classNames(
                            'is-hidden-touch',
                            styles['address-modal-footer-buttons'],
                            { [styles['is-validation']]: isValidation },

                        )}
                    >
                        {isValidation && (
                            <DefaultButton
                                onClick={() => {
                                    refuseAddress()
                                }}
                                text={tKey('refuse')}
                                disabled={status === Status.PENDING}
                            />
                        )}
                        <DefaultButton
                            onClick={() => {
                                onChange(null)
                                setAddressDataOptionSelected(null)
                            }}
                            text={tKey('cancel')}
                            disabled={status === Status.PENDING}
                        />
                        <PrimaryButton
                            type="submit"
                            text={tKey('save')}
                            disabled={!canSave}
                        />
                    </DialogFooter>
                </div>
                <div>
                    {isVisible && (
                        <AddressModalMap
                            mapMarker={{ lat: +address.lat || null, lng: +address.lon || null }}
                            isEnable={address.isManual}
                            setIsEnable={isMapEnable => {
                                setAddressDataOptionSelected(/** @type {any} */({ key: null }))
                                setAddress(prevState => ({
                                    ...prevState,
                                    // street: '',
                                    // city: '',
                                    // zipCode: '',
                                    // countryId: null,
                                    // lat: '',
                                    // lon: '',
                                    isManual: isMapEnable,
                                }))
                            }}
                            onMapMarkerUpdate={newVal => {
                                if (newVal.lat && newVal.lon)
                                    setAddress(prevAddress => ({ ...prevAddress, ...newVal }))
                                else
                                    setAddress(prevAddress => ({ ...prevAddress, lat: addressData.lat, lon: addressData.lon }))
                            }}
                            lang={lang}
                        />
                    )}
                </div>
                <DialogFooter
                    className={classNames(
                        'is-hidden-desktop',
                        styles['address-modal-footer-buttons'],
                        { [styles['is-validation']]: isValidation },
                    )}
                >
                    <DefaultButton
                        onClick={() => {
                            refuseAddress()
                        }}
                        text={tKey('refuse')}
                        disabled={status === Status.PENDING}
                    />
                    <DefaultButton
                        onClick={() => {
                            onChange(null)
                            setAddressDataOptionSelected(null)
                        }}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text={tKey('save')}
                        disabled={!canSave}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
