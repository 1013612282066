import React, {
    PureComponent,
} from 'react'
import {
    DefaultButton, DirectionalHint,
    Label, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick, getUpdatedList } from 'helpers/methods/common'
import Status from 'types/status'
import Stock from 'requests/objects/stock'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/stocks/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import StockList from 'components/pages/stocks/stock-list'
import HandleBlob from 'helpers/methods/blob'
import EAddressType from 'types/addresses/enums/addressType'

/**
 * @typedef {object} SearchParamsType
 * @property {number} articleId articleId
 * @property {number} clientId clientId
 * @property {number} addressId addressId
 * @property {string} containerCode containerCode
 * @property {string} name name
 */

/** @debug {import('app').AppProps>} */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexStocks extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Stock[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                articleId: 0,
                clientId: 0,
                addressId: 0,
                containerCode: '',
                name: '',
            },
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    articleId: queryParams.get('articleId') ? +queryParams.get('articleId') : null,
                    clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                    addressId: queryParams.get('addressId') ? +queryParams.get('addressId') : null,
                    containerCode: queryParams.get('containerCode') ? queryParams.get('containerCode') : '',
                    name: queryParams.get('name') ? queryParams.get('name') : '',
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang, me } = this.props
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        if (lang !== prevProps.lang)
            this.init()

        if (me.companyId !== prevProps.me.companyId)
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.stocksHandlerGetAll?.cancel()
        this.stocksHandlerExportAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('stocks'), key: 'stock', isCurrentItem: true },
        ])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.search()
    }

    /**
     * Search elements
     * @param {string} [orderBy] orderBy
     */
    search(orderBy = undefined) {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { stocksHandler, me } = this.props
            const { searchParams } = this.state
            try {
                this.stocksHandlerGetAll?.cancel()
                this.stocksHandlerGetAll = stocksHandler.getAll({
                    ...searchParams,
                    orderBy,
                    clientId: me.clientId ? me.clientId : searchParams.clientId,
                })
                const stocks = await this.stocksHandlerGetAll.fetch()
                this.setState({
                    items: stocks.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Export elements
     */
    export() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { stocksHandler } = this.props
            try {
                this.stocksHandlerExportAll = stocksHandler.export()
                const { fileName, blob } = await this.stocksHandlerExportAll.fetch()
                HandleBlob.download(blob, fileName)
                this.setState({ status: Status.RESOLVED })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError:
                    case NotImplementedError:
                    default:
                        this.setState({ status: Status.REJECTED })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, searchParams,
        } = this.state

        const { param, me } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                >
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            {!me.client && (
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('client')}
                                        options={param.clients.filter(x => x.useStocks)}
                                        selectedKey={searchParams.clientId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                clientId: option.key,
                                            },
                                        })}
                                    />
                                </Columns.Column>
                            )}
                            <Columns.Column size="one-fifth">
                                <FilteredVirtualCombobox
                                    label={this.tKey('platform')}
                                    options={param.addresses.filter(x => x.addressTypeId === EAddressType.Platform)}
                                    selectedKey={searchParams.addressId}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            addressId: option.key,
                                        },
                                    })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-fifth">
                                <TextField
                                    label={this.tKey('containerCode')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.containerCode}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, containerCode: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-fifth">
                                <TextField
                                    label={this.tKey('article')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.name}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, name: newVal } })}
                                />
                            </Columns.Column>
                            {!!me.client && (
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('article')}
                                        options={param.articles}
                                        selectedKey={searchParams.articleId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                articleId: option.key,
                                            },
                                        })}
                                    />
                                </Columns.Column>
                            )}
                            <Columns.Column size="one-fifth">
                                <div
                                    className={styles['action-filter-buttons']}
                                >
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <DefaultButton
                                            text={this.tKey('research')}
                                            primary
                                            split
                                            disabled={status === Status.PENDING}
                                            onClick={() => this.submitInput.current.click()}
                                            iconProps={{ iconName: 'Search' }}
                                            menuProps={{
                                                items: [
                                                    {
                                                        key: 'Search',
                                                        text: this.tKey('research'),
                                                        iconProps: { iconName: 'Search' },
                                                        onClick: () => this.submitInput.current.click(),
                                                    },
                                                    {
                                                        key: 'Export',
                                                        text: this.tKey('export'),
                                                        iconProps: { iconName: 'Export' },
                                                        onClick: () => this.export(),
                                                    },
                                                ],
                                            }}
                                        />
                                    </div>
                                    <div className={styles.separator} />
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <TooltipHost
                                            content={this.tKey('resetFilters')}
                                            directionalHint={DirectionalHint.topAutoEdge}
                                            delay={TooltipDelay.medium}
                                        >
                                            <DefaultButton
                                                disabled={status === Status.PENDING}
                                                styles={{
                                                    root: {
                                                        minWidth: '40px',
                                                        maxWidth: '40px',
                                                    },
                                                }}
                                                iconProps={{ iconName: 'ClearFilter' }}
                                                onClick={() => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        articleId: 0,
                                                        clientId: 0,
                                                        addressId: 0,
                                                        containerCode: '',
                                                        name: '',
                                                    },
                                                })}
                                            />
                                        </TooltipHost>
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>

                        <Columns />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </Card>
                <br />
                <StockList
                    lang={me.language}
                    onOrderByChange={orderBy => this.search(orderBy)}
                    status={status}
                    stocks={items}
                />
            </main>
        )
    }
}

IndexStocks.prototype.onColumnClick = onColumnClick
IndexStocks.prototype.flattenObj = flattenObj
IndexStocks.prototype.handleRenderColumn = handleRenderColumn
IndexStocks.prototype.tKey = tKey
IndexStocks.prototype.tObj = tObj
IndexStocks.prototype.getUpdatedList = getUpdatedList
