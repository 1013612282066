import React, {
    useState, useCallback, useRef,
} from 'react'
import {
    DefaultButton, DirectionalHint, MessageBarType, TextField, Toggle, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import AddressModal from 'components/pages/addressModal'
import ContactModal from 'components/pages/contactModal'
import useTranslate from 'helpers/hooks/useTranslate'
import AddressesHandler from 'requests/handlers/addressesHandler'
import AddressList from 'components/pages/clients/address-list'
import Address from 'requests/objects/address'
import Status from 'types/status'
import Contact from 'requests/objects/contact'
import Param from 'requests/objects/param'
import CompanyRule from 'requests/objects/companyRule'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/components/pages/companies/pivot-addresses.module.scss'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import parseJson from 'helpers/methods/parseJson'
import HandleBlob from 'helpers/methods/blob'
import ImportDataModal from 'components/modals/import-data-modal'
import User from 'requests/objects/user'
import { clientProfiles } from 'types/users/profiles'
import EAddressType from 'types/addresses/enums/addressType'

/**
 * @typedef {object} AddressIndexes
 * @property {number?} clientId - client id for client pivot
 * @property {number?} companyId - company id for company pivot
 */

/**
 * @typedef {object} SearchParamsType
 * @property {string} label label
 * @property {string} street street
 * @property {string} city city
 * @property {number} countryId countryId
 * @property {number} addressTypeId addressTypeId
 * @property {string} contact contact
 * @property {boolean} isVisible isVisible
 */

/**
 * @type {SearchParamsType}
 */
const defaultSearchParams = {
    label: '',
    street: '',
    city: '',
    countryId: null,
    contact: '',
    addressTypeId: null,
    isVisible: true,
}

/**
 * Pivot addresses
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {AddressesHandler} props.addressesHandler addressesHandler
 * @param {Address[]} props.items items
 * @param {AddressIndexes} props.indexes contains client id or company id
 * @param {Param} props.param param
 * @param {CompanyRule=} props.companyRules isOpeningTimeRequired
 * @param {function(import('redux/slices/common').PayloadModal):void} props.setModal Set Modal
 * @param {function(import('redux/slices/common').PayloadMessageBar):void} props.setMessageBar Set MessageBar
 * @param {function(import('redux/slices/user').PayloadMe):void=} [props.setMe] setMe
 * @param {User} props.me me
 * @returns {JSX.Element} Element
 */
export default function PivotAddresses({
    lang,
    addressesHandler,
    items,
    indexes,
    param,
    companyRules,
    setModal,
    setMessageBar,
    setMe = undefined,
    me,
}) {
    const { tObj, tKey } = useTranslate({ lang })
    const [status, setStatus] = useState(Status.IDLE)
    const [addresses, setAddresses] = useState(items)
    const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
    const [isContactModalVisible, setIsContactModalVisible] = useState(false)
    const [isIntegrateAddressModalVisible, setIsIntegrateAddressModalVisible] = useState(false)
    const [address, setAddress] = useState(new Address())
    const [contact, setContact] = useState(new Contact())
    const [searchParams, setSearchParams] = useState(defaultSearchParams)
    const [errorMessage, setErrorMessage] = useState('')
    const isCustomer = clientProfiles.includes(me.profileId)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const setAddressDefaultHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const deleteAddressHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address[]>>} */
    const searchAddressesHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address[]>>} */
    const handlerAddressImport = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const downloadTemplateHandler = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const downloadExampleHandler = useRef()

    const submitInput = useRef(null)
    const toolTipRef = useRef(null)

    const addContact = useCallback(
        /**
         * Add contact
         * @param {Address} addr addr
         */
        addr => {
            setContact(new Contact({ addressId: addr.addressId, address: addr }))
            setIsContactModalVisible(true)
        }, [],
    )

    const updateAddressList = useCallback((addr, isDeletion = false) => {
        const newAddresses = [...addresses]
        const index = newAddresses.findIndex(x => x.addressId === addr.addressId)

        if (index > -1)
            if (isDeletion)
                newAddresses.splice(index, 1)
            else
                newAddresses[index] = addr
        else
            newAddresses.push(addr)
        setAddresses(newAddresses)
    }, [addresses])

    const editContact = useCallback(
        /**
         * Edit contact
         * @param {Contact} contactToEdit contactToEdit
         */
        contactToEdit => {
            setContact(contactToEdit)
            setIsContactModalVisible(true)
        }, [],
    )

    const editAddress = useCallback(
        /**
         * Edit address
         * @param {Address} addressToEdit addressToEdit
         */
        addressToEdit => {
            setAddress(addressToEdit)
            setIsAddressModalVisible(true)
        }, [],
    )

    const deleteAddress = useCallback(
        /**
         * Delete address
         * @param {Address} addressToDelete addressToDelete
         */
        addressToDelete => {
            setModal({
                show: true,
                title: tKey('removeAddress'),
                subTitle: tKey('sureRemoveAddress'),
                callback: async () => {
                    try {
                        deleteAddressHandler.current = addressesHandler.removeById(addressToDelete.addressId)
                        const addr = await deleteAddressHandler.current.fetch()
                        updateAddressList(addr, true)
                    } catch (error) {
                        setMessageBar({
                            isDisplayed: true,
                            type: MessageBarType.error,
                            message: tObj(error) ?? tKey('anErrorHasOccurred'),
                        })
                    }
                },
            })
        }, [addressesHandler, updateAddressList, setMessageBar, setModal, tKey, tObj],
    )

    const onImport = useCallback(async file => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            handlerAddressImport.current = addressesHandler.import(file, indexes.clientId)
            const res = await handlerAddressImport.current.fetch()
            setAddresses(res)
            setIsIntegrateAddressModalVisible(false)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error ?? 'anErrorHasOccurred')
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [addressesHandler, indexes.clientId])

    /**
     * Download template
     */
    const onDownloadTemplate = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            downloadTemplateHandler.current = addressesHandler.downloadTemplate()
            const blob = await downloadTemplateHandler.current.fetch()
            HandleBlob.download(blob, 'template-adresses-import.xlsx')
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error ?? 'anErrorHasOccurred')
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [addressesHandler])

    /**
     * Download exemple
     */
    const onDownloadExample = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            downloadExampleHandler.current = addressesHandler.downloadExample()
            const blob = await downloadExampleHandler.current.fetch()
            HandleBlob.download(blob, 'exemple-adresses-import.xlsx')
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error ?? 'anErrorHasOccurred')
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [addressesHandler])

    const addAddress = useCallback(() => {
        setAddress(new Address({ ...indexes, addressTypeId: indexes.companyId ? EAddressType.Platform : 0, companyId: companyRules?.companyId }))
        setIsAddressModalVisible(true)
    }, [companyRules?.companyId, indexes])

    const setAddressToDefault = useCallback(
        /**
         * Add or remove to favorites address
         * @param {number} addressId addressId
         */
        async addressId => {
            try {
                setStatus(Status.PENDING)
                setAddressDefaultHandler.current = addressesHandler.setAddressToDefault(addressId)
                const addressReturned = await setAddressDefaultHandler.current.fetch()
                setStatus(Status.RESOLVED)
                setAddresses(addresses.map(addr => ({
                    ...addr,
                    isDefault: addr.addressId === addressReturned.addressId ? addressReturned.isDefault : false,
                })))
                if (isCustomer && setMe)
                    setMe({
                        me: {
                            ...me,
                            client: {
                                ...me.client,
                                addresses: me.client.addresses.map(x => {
                                    if (x.addressId === addressReturned.addressId)
                                        return addressReturned
                                    return x
                                }),
                            },
                        },
                    })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setStatus(Status.REJECTED)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [addresses, addressesHandler, isCustomer, me, setMe],
    )

    const search = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            searchAddressesHandler.current?.cancel()
            searchAddressesHandler.current = addressesHandler.getAll({ ...searchParams, ...indexes })
            const res = await searchAddressesHandler.current.fetch()
            setAddresses(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setAddresses([])
                    setStatus(Status.RESOLVED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [addressesHandler, indexes, searchParams])

    return (
        <main className={styles['pivot-addresses']}>
            <Card
                title={tKey('filters')}
                iconName="FilterSettings"
                headerComponent={(
                    <div
                        className={styles['action-filter-buttons']}
                    >
                        <div>
                            <DefaultButton
                                text={tKey('research')}
                                primary
                                disabled={status === Status.PENDING}
                                onClick={() => submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                            />
                        </div>
                        <div className={styles.separator} />
                        <div>
                            <TooltipHost
                                content={tKey('resetFilters')}
                                directionalHint={DirectionalHint.topAutoEdge}
                                delay={TooltipDelay.medium}
                                componentRef={toolTipRef}
                            >
                                <DefaultButton
                                    disabled={status === Status.PENDING}
                                    styles={{
                                        root: {
                                            minWidth: '40px',
                                            maxWidth: '40px',
                                        },
                                    }}
                                    iconProps={{ iconName: 'ClearFilter' }}
                                    onClick={() => setSearchParams(defaultSearchParams)}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                )}
            >
                {/* {isFilterCardReduced && ( */}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label={tKey('addressType')}
                                options={param.addressTypes.map(({ key, text }) => ({
                                    key,
                                    text: tObj(parseJson(text)),
                                }))}
                                selectedKey={searchParams.addressTypeId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => setSearchParams(prev => ({ ...prev, addressTypeId: option.key }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label={tKey('labelAddress')}
                                disabled={status === Status.PENDING}
                                value={searchParams.label}
                                onChange={(ev, newVal) => setSearchParams(prev => ({ ...prev, label: newVal }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label={tKey('street')}
                                disabled={status === Status.PENDING}
                                value={searchParams.street}
                                onChange={(ev, newVal) => setSearchParams(prev => ({ ...prev, street: newVal }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label={tKey('cityZipcode')}
                                disabled={status === Status.PENDING}
                                value={searchParams.city}
                                onChange={(ev, newVal) => setSearchParams(prev => ({ ...prev, city: newVal }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label={tKey('country')}
                                options={param.countries.map(({ key, text }) => ({
                                    key,
                                    text: tObj(parseJson(text)),
                                }))}
                                selectedKey={searchParams.countryId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => setSearchParams(prev => ({ ...prev, countryId: option.key }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label={tKey('contact')}
                                disabled={status === Status.PENDING}
                                value={searchParams.contact}
                                onChange={(ev, newVal) => setSearchParams(prev => ({ ...prev, contact: newVal }))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <Toggle
                                onText={tKey('yes')}
                                offText={tKey('no')}
                                label={tKey('isVisible')}
                                styles={{ container: { transform: 'translateY(6px)' } }}
                                checked={searchParams.isVisible}
                                onChange={(ev, checked) => setSearchParams(prevState => ({ ...prevState, isVisible: checked }))}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={submitInput}
                        tabIndex={-1}
                    />
                </form>
            </Card>
            <br />
            <Card
                iconName="MapPin"
                title={tKey('addresses')}
                headerComponent={!isCustomer && (
                    <>
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                setIsIntegrateAddressModalVisible(true)
                            }}
                            text={tKey('integrateAddresses')}
                        />
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                addAddress()
                            }}
                            text={tKey('add')}
                        />
                    </>
                )}
                hasDividerMargin={false}
            >
                <AddressList
                    addContact={addContact}
                    editContact={editContact}
                    editAddress={editAddress}
                    deleteAddress={deleteAddress}
                    items={addresses}
                    lang={lang}
                    setAddressToDefault={setAddressToDefault}
                    status={status}
                    isCompany={!!indexes.companyId}
                    isCustomer={isCustomer}
                />
            </Card>
            <AddressModal
                addressData={address}
                isVisible={isAddressModalVisible}
                handler={addressesHandler}
                isReadOnly={false}
                onChange={addressReturned => {
                    if (addressReturned)
                        updateAddressList(addressReturned)
                    setIsAddressModalVisible(false)
                }}
                lang={lang}
                param={param}
                companyRules={companyRules}
                isAdmin={!isCustomer}
            />
            <ContactModal
                lang={lang}
                isVisible={isContactModalVisible}
                contactData={contact}
                handler={addressesHandler}
                onChange={contactReturned => {
                    if (contactReturned) {
                        const addressToChange = addresses.find(a => a.addressId === contactReturned.addressId)
                        updateAddressList(new Address({
                            ...addressToChange,
                            contact: contactReturned,
                            contactId: contactReturned.contactId,
                        }))
                    }
                    setIsContactModalVisible(false)
                }}
                companyId={companyRules.companyId}
            />
            <ImportDataModal
                lang={lang}
                isVisible={isIntegrateAddressModalVisible}
                setIsVisible={setIsIntegrateAddressModalVisible}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                onDownloadTemplate={onDownloadTemplate}
                onDownloadExample={onDownloadExample}
                onImport={onImport}
                status={status}
                title={tKey('integrateAddresses')}
            />
        </main>
    )
}
