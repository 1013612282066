import React, { PureComponent } from 'react'
import {
    DefaultButton, MessageBarType,
} from '@fluentui/react'
import Order, { ErrorOrder } from 'requests/objects/order'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import history from 'helpers/history'
import styles from 'styles/pages/orders/[id]/client/id-client.module.scss'
import WorkflowLight from 'components/pages/workflowLight'
import PickupStep from 'components/pages/orders/[id]/client/pickupStep'
import DeliveryStep from 'components/pages/orders/[id]/client/deliveryStep'
import ConfirmationStep from 'components/pages/orders/[id]/client/confirmationStep'
import EOrderStatus from 'types/orders/enums/orderStatus'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import parseJson from 'helpers/methods/parseJson'
import { ErrorDeliveryData } from 'requests/objects/order/deliveryData'
import ECompany from 'types/companies/enums/company'
import MaintenancePortalInfo from 'components/pages/maintenancePortalInfo'
import EOrderType from 'types/orders/enums/orderType'
import EAddressType from 'types/addresses/enums/addressType'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdClientOrders extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: false,
            /** @type {Order} Element find from API */
            item: new Order(),
            /** @type {ErrorOrder} Errors */
            errorField: new ErrorOrder(),
            /** @type {ErrorDeliveryData} Errors */
            deliveryErrorField: new ErrorDeliveryData(),
            /** Current page index */
            currentPageIndex: 0,
            /** Delviery index to display */
            deliveryIndex: 0,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps, prevState) {
        const {
            match, lang,
        } = this.props

        const { currentPageIndex } = this.state

        if (match?.params?.orderId !== prevProps?.match?.params?.orderId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                this.setupBreadcrumb()
                this.setupCommandBar()
            }

        if (lang !== prevProps.lang) {
            this.setupBreadcrumb()
            this.setupCommandBar()
        }

        if (currentPageIndex !== prevState.currentPageIndex)
            this.setupCommandBar()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.ordersHandlerGetById?.cancel()
        this.ordersHandlerUpsert?.cancel()
        this.validOrderHandler?.cancel()
        // this.saveTemporarilyHandler?.cancel()
        this.removeDeliveryDataHandler?.cancel()
    }

    // /**
    //  * Get last item breadcrumb
    //  * @returns {import('@fluentui/react').IBreadcrumbItem[]} Items
    //  */
    // getLastBreadcrumb() {
    //     const { currentPageIndex } = this.state
    //     const items = []

    //     if (currentPageIndex >= 0)
    //         items.push({
    //             text: this.tKey('pickup'),
    //             key: 'pickup',
    //             isCurrentItem: true,
    //         })
    //     if (currentPageIndex >= 1)
    //         items.push({
    //             text: this.tKey('deliverys'),
    //             key: 'deliverys',
    //             isCurrentItem: true,
    //         })
    //     if (currentPageIndex >= 2)
    //         items.push({
    //             text: this.tKey('confirmation'),
    //             key: 'confirmation',
    //             isCurrentItem: true,
    //         })

    //     return items
    // }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props
        const { item } = this.state

        const breadCrumb = [
            { text: this.tKey('myOrders'), key: 'all-order', href: '/orders' },
            {
                text: `${this.tKey('order')}${item.orderId ? ` n°${item.orderId}` : ''}`,
                key: 'one-order',
                isCurrentItem: true,
            },
        ]

        setBreadcrumb(breadCrumb)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props
        const { currentPageIndex, item } = this.state
        setCommand([])

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items
         */
        this.commands = [
            {
                key: 'back',
                text: this.tKey('return'),
                iconProps: { iconName: 'Back' },
                onClick: () => {
                    if (item.currentWorkflowStepId && item.currentWorkflowStepId !== EWorkflowStep.Creation && currentPageIndex !== 2)
                        this.setState({ currentPageIndex: 2 })
                    else
                        history.goBack()
                },
            },
        ]

        setCommand(this.commands)
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, ordersHandler, setMessageBar, me: { companyId, client },
        } = this.props

        setMessageBar({ isDisplayed: false })

        // If there is an id in URL, get element by id
        if (match?.params?.orderId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.order) // If object came from history push with a create
                    this.setState({
                        item: location.state?.order,
                        status: Status.RESOLVED,
                    }, () => {
                        this.setupBreadcrumb()
                        this.setupCommandBar()
                    })
                else
                    try {
                        this.ordersHandlerGetById = ordersHandler.getById(match?.params?.orderId)
                        const order = await this.ordersHandlerGetById.fetch()
                        let deliveryIndex = 0
                        let currentPageIndex = 2
                        order.deliveryDatas.forEach((deliveryData, index) => {
                            if (deliveryData.temporarySaved) {
                                deliveryIndex = index
                                currentPageIndex = 1
                            }
                        })
                        this.setState({
                            item: order,
                            currentPageIndex,
                            deliveryIndex,
                            isReadOnly: order.currentWorkflowStepId !== EWorkflowStep.Creation,
                            status: Status.RESOLVED,
                        }, () => {
                            this.setupBreadcrumb()
                            this.setupCommandBar()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError:
                                this.setState({ errorField: /** @type {InvalidEntityError<ErrorOrder>} */(error).errorField })
                                setCommand(this.commands)
                                break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commands)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                const queryParams = new URLSearchParams(window.location.search)
                const orderTypeId = queryParams.get('type') ? +queryParams.get('type') : undefined
                const addressId = client.addresses.find(
                    addr => {
                        if (!addr.isDefault)
                            return false
                        switch (orderTypeId) {
                            case EOrderType.Redelivery:
                            case EOrderType.Reprise:
                                return addr.addressTypeId === EAddressType.Delivery
                            case EOrderType.Delivery:
                                return addr.addressTypeId === EAddressType.PickUp
                            case EOrderType.DocksideShed:
                            default:
                                return false
                        }
                    },
                )?.addressId ?? null
                this.setState({
                    item: new Order({
                        deliveryDatas: [],
                        orderTypeId,
                        pickupAddressData: { addressId },
                        pickupDateData: {
                            startPickupTime: (companyId === ECompany.Pineau && '08:00') || (companyId === ECompany.JetFreeze && '00:00') || '',
                            endPickupTime: (companyId === ECompany.Pineau && '18:00') || (companyId === ECompany.JetFreeze && '23:59') || '',
                        },
                        pickupInstructionData: {
                            clientActivityId: client?.clientActivities?.length === 1
                                ? client.clientActivities[0].clientActivityId
                                : client?.clientActivities?.find(x => x.isDefault)?.clientActivityId ?? null,
                            vehicleEquipmentId: companyId === ECompany.Raud ? 1 : null,
                            accessibilityId: companyId === ECompany.Raud ? 1 : null,
                        },
                    }),
                    status: Status.IDLE,
                }, () => {
                    this.setupBreadcrumb()
                    this.setupCommandBar()
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     * @param {boolean} temporarySaved If true, will save delivery data as temporary
     */
    upsert(temporarySaved = false) {
        const {
            setCommand, ordersHandler, match, setMessageBar,
        } = this.props

        const {
            item, currentPageIndex, deliveryIndex, isReadOnly,
        } = this.state

        if (isReadOnly && currentPageIndex < 2) {
            this.setState({ currentPageIndex: currentPageIndex + 1 })
            return
        }

        this.setState({ status: Status.PENDING, errorField: new ErrorOrder(), deliveryErrorField: new ErrorDeliveryData() }, async () => {
            setMessageBar({ isDisplayed: false })
            try {
                this.ordersHandlerUpsert = currentPageIndex === 0
                    ? ordersHandler.upsert(item, match?.params?.orderId)
                    : ordersHandler.updateDeliveryData(item.deliveryDatas[deliveryIndex], match?.params?.orderId, temporarySaved)

                const order = await this.ordersHandlerUpsert.fetch()

                setMessageBar({ isDisplayed: false })

                this.setState({
                    item: order,
                    status: Status.RESOLVED,
                    isReadOnly: order.currentWorkflowStepId !== EWorkflowStep.Creation,
                    // eslint-disable-next-line no-nested-ternary
                    currentPageIndex: temporarySaved ? 0 : currentPageIndex < 2 ? currentPageIndex + 1 : currentPageIndex,
                }, () => {
                    this.setupBreadcrumb()
                    this.setupCommandBar()
                })

                if (!match?.params?.orderId)
                    history.replace(`/orders/${order.orderId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            if (currentPageIndex === 0)
                                this.setState({ errorField: /** @type {InvalidEntityError<ErrorOrder>} */(error).errorField })
                            else
                                this.setState({ deliveryErrorField: /** @type {InvalidEntityError<ErrorDeliveryData>} */(error).errorField })
                            setCommand(this.commands)
                            break
                        default:
                            setCommand(this.commands)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * save temporarily order
     */
    // saveTemporarily() {
    //     const {
    //         setCommand, ordersHandler, setMessageBar,
    //     } = this.props
    //     const { item } = this.state

    //     this.setState({ status: Status.PENDING }, async () => {
    //         setCommand([])
    //         setMessageBar({ isDisplayed: false })
    //         try {
    //             this.saveTemporarilyHandler = ordersHandler.saveTemporarily(item.orderId)
    //             await this.saveTemporarilyHandler.fetch()

    //             this.setState({
    //                 item: { ...item, savedTemporarily: true },
    //                 status: Status.RESOLVED,
    //             })
    //         } catch (error) {
    //             this.setState({ status: Status.REJECTED }, () => {
    //                 switch (error?.constructor) {
    //                     case CancelRequestError:
    //                     case UnauthorizedError:
    //                     case NotImplementedError:
    //                     case InvalidEntityError:
    //                     default:
    //                         setCommand(this.commands)
    //                         // eslint-disable-next-line no-console
    //                         console.error(error)
    //                         break
    //                 }
    //             })
    //         }
    //     })
    // }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    validOrder() {
        const {
            setCommand, ordersHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.validOrderHandler = ordersHandler.validOrder(item.orderId)
                const order = await this.validOrderHandler.fetch()

                this.setState({
                    item: order,
                    isReadOnly: true,
                    status: Status.RESOLVED,
                }, () => {
                    this.setupBreadcrumb()
                    this.setupCommandBar()
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError:
                        case NotImplementedError:
                        case InvalidEntityError:
                        default:
                            setCommand(this.commands)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Function called when additional delivery data is canceled
     * @param {number} deliveryDataId deliveryDataID
     */
    cancelDeliveryDataEntry(deliveryDataId) {
        const {
            setCommand, ordersHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.removeDeliveryDataHandler = ordersHandler.removeDeliveryData(item.orderId, deliveryDataId)
                const order = await this.removeDeliveryDataHandler.fetch()

                this.setState({
                    item: order,
                    status: Status.RESOLVED,
                    currentPageIndex: 2,
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError:
                        case NotImplementedError:
                        case InvalidEntityError:
                        default:
                            setCommand(this.commands)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, currentPageIndex, deliveryIndex, deliveryErrorField,
        } = this.state
        const {
            param, lang, addressesHandler, ordersHandler, editParam, addParam, removeParam,
            filesHandler, setCommand, setModal, setMessageBar, me,
        } = this.props

        if (status === Status.PENDING)
            return <Loader />

        if (!me.company.isOperational)
            return (<MaintenancePortalInfo lang={lang} />)

        return (
            <main className={styles['id-client']}>
                <div className={styles['id-client-top']}>
                    <div />
                    <WorkflowLight
                        steps={[
                            { label: this.tKey('pickup'), isCompleted: currentPageIndex >= 0 },
                            { label: this.tKey('deliverys'), isCompleted: currentPageIndex >= 1 },
                            {
                                label: this.tKey(currentPageIndex >= 2 && item.orderStatusId === EOrderStatus.Rejected ? 'rejected' : 'confirmation'),
                                isCompleted: currentPageIndex >= 2 && item.orderStatusId === EOrderStatus.Validated,
                                isActive: currentPageIndex >= 2 && item.orderStatusId !== EOrderStatus.Validated,
                                isWarning: currentPageIndex >= 2 && item.orderStatusId === EOrderStatus.Rejected,
                            },
                        ]}
                    />
                    <div className={styles['id-client-btns']}>
                        {(!item.currentWorkflowStepId || item.currentWorkflowStepId === EWorkflowStep.Creation) && (
                            <DefaultButton
                                text={this.tKey('cancelEntry')}
                                onClick={() => {
                                    if (!item.orderId)
                                        history.push('/orders')
                                    else if (currentPageIndex === 1 && deliveryIndex > 0)
                                        this.cancelDeliveryDataEntry(item.deliveryDatas[deliveryIndex]?.deliveryDataId)
                                    else
                                        setModal({
                                            show: true,
                                            title: this.tKey('cancelOrder'),
                                            subTitle: this.tKey('sureCancelOrder'),
                                            callback: () => {
                                                this.setState({ status: Status.PENDING }, async () => {
                                                    setCommand([])
                                                    setMessageBar({ isDisplayed: false })
                                                    try {
                                                        this.ordersHandlerRemoveById = ordersHandler.removeById(item.orderId)
                                                        await this.ordersHandlerRemoveById.fetch()
                                                        history.push('/orders')
                                                        setMessageBar({ // We must set the messagebar after change page, to force re add it
                                                            isDisplayed: true,
                                                            type: MessageBarType.success,
                                                            message: this.tKey('modalCancelMessage'),
                                                        })
                                                    } catch (error) {
                                                        switch (error?.constructor) {
                                                            case CancelRequestError:
                                                            case UnauthorizedError:
                                                            case InvalidEntityError: break
                                                            case NotImplementedError:
                                                                this.setState({ status: Status.REJECTED })
                                                                // eslint-disable-next-line no-console
                                                                console.error(error)
                                                                break
                                                            default:
                                                                setCommand(this.commands)
                                                                this.setState({ status: Status.REJECTED })
                                                                // eslint-disable-next-line no-console
                                                                console.error(error)
                                                                break
                                                        }
                                                    }
                                                })
                                            },
                                        })
                                }}
                            />
                        )}
                        {currentPageIndex === 1 && (
                            <DefaultButton
                                text={this.tKey('previous')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Back' }}
                                onClick={() => (isReadOnly ? this.setState({ currentPageIndex: 0 }) : this.upsert(true))}
                            />
                        )}

                        {currentPageIndex < 2 && (
                            <DefaultButton
                                text={this.tKey('next')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Forward' }}
                                styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                onClick={() => this.submitInput.current.click()}
                            />
                        )}
                        {currentPageIndex === 2 && (
                            <DefaultButton
                                text={this.tKey('validate')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Forward' }}
                                styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                onClick={e => {
                                    e.preventDefault()
                                    this.validOrder()
                                }}
                                disabled={
                                    item.currentWorkflowStepId !== EWorkflowStep.Creation
                                    || item.orderStatusId !== EOrderStatus.ValidDraft
                                }
                            />
                        )}
                    </div>
                </div>

                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    {currentPageIndex === 0 && (
                        <PickupStep
                            lang={lang}
                            item={item}
                            isReadOnly={isReadOnly}
                            errorField={errorField}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            addressesHandler={addressesHandler}
                            editParam={editParam}
                            addParam={addParam}
                            removeParam={removeParam}
                            me={me}
                        />
                    )}

                    {currentPageIndex === 1 && (
                        <DeliveryStep
                            lang={lang}
                            item={item}
                            deliveryIndex={deliveryIndex}
                            isReadOnly={isReadOnly}
                            errorField={deliveryErrorField}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            addressesHandler={addressesHandler}
                            editParam={editParam}
                            addParam={addParam}
                            removeParam={removeParam}
                            filesHandler={filesHandler}
                            me={me}
                        />
                    )}

                    {currentPageIndex === 2 && (
                        <ConfirmationStep
                            lang={lang}
                            item={item}
                            setDeliveryIndex={newIndex => this.setState({ deliveryIndex: newIndex })}
                            setCurrentPageIndex={newIndex => this.setState({ currentPageIndex: newIndex })}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            ordersHandler={ordersHandler}
                            setModal={setModal}
                            setMessageBar={setMessageBar}
                            userCanEdit={!isReadOnly}
                            me={me}
                        />
                    )}

                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                {currentPageIndex !== 2 && (
                    <div
                        className={styles['id-client-btns']}
                        style={{ marginTop: '30px' }}
                    >
                        {(!item.currentWorkflowStepId || item.currentWorkflowStepId === EWorkflowStep.Creation) && (
                            <DefaultButton
                                text={this.tKey('cancelEntry')}
                                onClick={() => {
                                    if (!item.orderId)
                                        history.push('/orders')
                                    else if (currentPageIndex === 1 && deliveryIndex > 0)
                                        this.cancelDeliveryDataEntry(item.deliveryDatas[deliveryIndex]?.deliveryDataId)
                                    else
                                        setModal({
                                            show: true,
                                            title: this.tKey('cancelOrder'),
                                            subTitle: this.tKey('sureCancelOrder'),
                                            callback: () => {
                                                this.setState({ status: Status.PENDING }, async () => {
                                                    setCommand([])
                                                    setMessageBar({ isDisplayed: false })
                                                    try {
                                                        this.ordersHandlerRemoveById = ordersHandler.removeById(item.orderId)
                                                        await this.ordersHandlerRemoveById.fetch()
                                                        history.push('/orders')
                                                        setMessageBar({ // We must set the messagebar after change page, to force re add it
                                                            isDisplayed: true,
                                                            type: MessageBarType.success,
                                                            message: this.tKey('modalCancelMessage'),
                                                        })
                                                    } catch (error) {
                                                        switch (error?.constructor) {
                                                            case CancelRequestError:
                                                            case UnauthorizedError:
                                                            case InvalidEntityError: break
                                                            case NotImplementedError:
                                                                this.setState({ status: Status.REJECTED })
                                                                // eslint-disable-next-line no-console
                                                                console.error(error)
                                                                break
                                                            default:
                                                                setCommand(this.commands)
                                                                this.setState({ status: Status.REJECTED })
                                                                // eslint-disable-next-line no-console
                                                                console.error(error)
                                                                break
                                                        }
                                                    }
                                                })
                                            },
                                        })
                                }}
                            />
                        )}
                        {currentPageIndex === 1 && (
                            <DefaultButton
                                text={this.tKey('previous')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Back' }}
                                onClick={() => (isReadOnly ? this.setState({ currentPageIndex: 0 }) : this.upsert(true))}
                            />
                        )}

                        {currentPageIndex < 2 && (
                            <DefaultButton
                                text={this.tKey('next')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Forward' }}
                                styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                onClick={() => this.submitInput.current.click()}
                            />
                        )}
                        {currentPageIndex === 2 && (
                            <DefaultButton
                                text={this.tKey('validate')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Forward' }}
                                styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                onClick={e => {
                                    e.preventDefault()
                                    this.validOrder()
                                }}
                                disabled={
                                    item.currentWorkflowStepId !== EWorkflowStep.Creation
                                    || item.orderStatusId !== EOrderStatus.ValidDraft
                                }
                            />
                        )}
                    </div>
                )}
            </main>
        )
    }
}

IdClientOrders.prototype.tKey = tKey
IdClientOrders.prototype.tObj = tObj
IdClientOrders.prototype.parseJson = parseJson
