/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'
import File from 'requests/objects/file'
import Address from 'requests/objects/address'
import FileCategory from 'requests/objects/fileCategory'
import Company from 'requests/objects/company'
import DeliveryType from 'requests/objects/deliveryType'
import SupportContact from 'requests/objects/supportContact'
import Country from 'requests/objects/country'
import Support from 'requests/objects/support'
import Article from 'requests/objects/article'
import DeliveryDateType from 'requests/objects/delivery-date-type'
import OrderType from 'requests/objects/orderType'

/**
 * Client activity Object
 */
export class ClientActivity {
    /**
     * @param {object} data data
     * @param {number=} data.clientActivityId clientActivityId
     * @param {string=} data.code code
     * @param {boolean=} data.isDefault isDefault
     */
    constructor({
        clientActivityId = 0,
        code = '',
        isDefault = false,
    } = {}) {
        this.clientActivityId = clientActivityId
        this.code = code
        this.isDefault = isDefault
    }
}

/**
 * Client Object
 */
export default class Client extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.clientId clientId
     * @param {string=} data.name name
     * @param {string=} data.clientTmsId clientTmsId
     * @param {string=} data.street street
     * @param {string=} data.city city
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.countryId countryId
     * @param {object=} data.country country
     * @param {number=} data.defaultDeliveryTypeId defaultDeliveryTypeId
     * @param {object=} data.defaultDeliveryType defaultDeliveryType
     * @param {number=} data.deliveryDateTypeId deliveryDateTypeId
     * @param {object=} data.deliveryDateType deliveryDateType
     * @param {number=} data.minDays minDays
     * @param {string=} data.phoneNumber phoneNumber
     * @param {string=} data.email email
     * @param {boolean=} data.isRisky isRisky
     * @param {boolean=} data.useStocks useStocks
     * @param {boolean=} data.isOrderSendingEmailAutomatic isOrderSendingEmailAutomatic
     * @param {number=} data.labelFormat labelFormat
     * @param {boolean=} data.confirmationEmailEntry confirmationEmailEntry
     * @param {boolean=} data.canCreateOrder canCreateOrder
     * @param {boolean=} data.isFootageInformable isFootageInformable
     * @param {object[]=} data.addresses addresses
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {object[]=} data.users users
     * @param {number=} data.logoId logoId
     * @param {object=} data.logo logo
     * @param {object[]=} data.fileCategories fileCategories
     * @param {object[]=} data.authorizedDdeliveryTypes authorizedDdeliveryTypes
     * @param {object[]=} data.visibleOperatingCenters visibleOperatingCenters
     * @param {string=} data.comfourNumber comfourNumber
     * @param {object[]=} data.supportContacts supportContacts
     * @param {object[]=} data.supports supports
     * @param {object[]=} data.articles articles
     * @param {object[]=} data.clientActivities clientActivities
     * @param {object[]=} data.orderTypes orderTypes
     */
    constructor({
        clientId,
        name = '',
        clientTmsId = '',
        street = '',
        city = '',
        zipCode = '',
        countryId = null,
        country,
        defaultDeliveryTypeId = 0,
        defaultDeliveryType,
        deliveryDateTypeId = null,
        deliveryDateType,
        minDays = 0,
        phoneNumber = '',
        email = '',
        isRisky = false,
        useStocks = false,
        isOrderSendingEmailAutomatic = false,
        confirmationEmailEntry = true,
        labelFormat = 8,
        canCreateOrder = true,
        isFootageInformable = false,
        addresses,
        companyId,
        company,
        users,
        logoId,
        logo,
        fileCategories = [],
        authorizedDdeliveryTypes = [],
        visibleOperatingCenters = [],
        comfourNumber = '',
        // defaultOperatingCenterId = null,
        supportContacts = [],
        supports = [],
        articles = [],
        clientActivities = [],
        orderTypes = [],
        ...data
    } = {}) {
        super(data)
        this.clientId = clientId
        this.name = name
        this.clientTmsId = clientTmsId
        this.street = street
        this.city = city
        this.zipCode = zipCode
        this.countryId = countryId
        this.country = country ? new Country(country) : undefined
        this.defaultDeliveryTypeId = defaultDeliveryTypeId
        this.defaultDeliveryType = defaultDeliveryType ? new DeliveryType(defaultDeliveryType) : undefined
        this.deliveryDateTypeId = deliveryDateTypeId
        this.deliveryDateType = deliveryDateType ? new DeliveryDateType(deliveryDateType) : undefined
        this.minDays = minDays
        this.phoneNumber = phoneNumber
        this.email = email
        this.isRisky = isRisky
        this.useStocks = useStocks
        this.isOrderSendingEmailAutomatic = isOrderSendingEmailAutomatic
        this.confirmationEmailEntry = confirmationEmailEntry
        this.labelFormat = labelFormat
        this.canCreateOrder = canCreateOrder
        this.isFootageInformable = isFootageInformable
        this.addresses = addresses?.map(x => new Address(x)) ?? []
        this.companyId = companyId
        this.company = company ? new Company(company) : undefined
        this.users = users?.map(x => new User(x)) ?? []
        this.logoId = logoId
        this.logo = logo ? new File(logo) : undefined
        this.fileCategories = fileCategories?.map(x => new FileCategory(x)) ?? []
        this.authorizedDdeliveryTypes = authorizedDdeliveryTypes?.map(x => new DeliveryType(x)) ?? []
        this.authorizedDdeliveryTypeIds = this.authorizedDdeliveryTypes?.map(x => x.deliveryTypeId) ?? []
        this.visibleOperatingCenters = visibleOperatingCenters?.map(x => new Address(x)) ?? []
        this.visibleOperatingCenterIds = this.visibleOperatingCenters?.map(x => x.addressId) ?? []
        this.comfourNumber = comfourNumber
        // this.defaultOperatingCenterId = defaultOperatingCenterId
        this.supportContacts = supportContacts?.map(x => new SupportContact(x)) ?? []
        this.supports = supports?.map(x => new Support(x)) ?? []
        this.articles = articles?.map(x => new Article(x)) ?? []
        this.clientActivities = clientActivities?.map(x => new ClientActivity(x)) ?? []
        this.orderTypes = orderTypes?.map(x => new OrderType(x)) ?? []
        this.orderTypeIds = this.orderTypes?.map(x => x.orderTypeId) ?? []
    }
}

/**
 * Client Object used to bind error message
 */
export class ErrorClient extends ErrorBase {
    constructor() {
        super()
        this.clientId = ''
        this.name = ''
        this.clientTmsId = ''
        this.street = ''
        this.city = ''
        this.zipCode = ''
        this.countryId = ''
        this.phoneNumber = ''
        this.email = ''
        this.isRisky = ''
        this.useStocks = ''
        this.isOrderSendingEmailAutomatic = ''
        this.canCreateOrder = ''
        this.isFootageInformable = ''
        this.companyId = ''
        this.logoId = ''
        this.logo = ''
        this.authorizedDdeliveryTypeIds = ''
        this.visibleOperatingCenterIds = ''
        this.comfourNumber = ''
        // this.defaultOperatingCenterId = ''
        this.deliveryDateTypeId = ''
        this.minDays = ''
        this.defaultDeliveryTypeId = ''
        this.orderTypeIds = ''
        this.clientActivities = ''
        this.labelFormat = ''
    }
}
