/**
 * @enum {number}
 */
const EAddressType = {
    PickUp: 1,
    Delivery: 2,
    Redelivery: 3,
    Platform: 4,
}

export default EAddressType
