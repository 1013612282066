// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects/client'

/**
 * Article
 */
export default class Article extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.articleId articleId
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {string=} data.name name
     * @param {string=} data.articleTmsId articleTmsId
     * @param {string=} data.articleTypeTmsId articleTypeTmsId
     * @param {number=} data.weight weight
     * @param {number=} data.footage footage
     * @param {boolean=} data.calcFootage calcFootage
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     * @param {boolean=} data.isVisible isVisible
     */
    constructor({
        articleId,
        clientId,
        client,
        name,
        articleTmsId,
        articleTypeTmsId,
        weight,
        footage,
        calcFootage = true,
        long,
        width,
        height,
        isVisible = true,
        ...data
    }) {
        super(data)
        this.articleId = articleId
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.name = name
        this.articleTmsId = articleTmsId
        this.articleTypeTmsId = articleTypeTmsId
        this.weight = Number.isNaN(+weight) ? null : +weight
        this.footage = Number.isNaN(+footage) ? null : +footage
        this.calcFootage = calcFootage
        this.long = Number.isNaN(+long) ? null : +long
        this.width = Number.isNaN(+width) ? null : +width
        this.height = Number.isNaN(+height) ? null : +height
        this.isVisible = isVisible
    }
}

/**
 * Delivery data Object used to bind error message
 */
export class ErrorArticle extends ErrorBase {
    constructor() {
        super()
        this.clientId = ''
        this.name = ''
        this.articleTmsId = ''
        this.articleTypeTmsId = ''
        this.weight = ''
        this.footage = ''
        this.long = ''
        this.width = ''
        this.height = ''
        this.calcFootage = ''
        this.isVisible = ''
    }
}
